import { initMasonry } from "./masonry.js"
import { qsa } from "./utils.js"

const observer = new IntersectionObserver((entries, observer) => {
  for (const entry of entries) {
    const { isIntersecting, target } = entry
    if (isIntersecting) {
      observer.unobserve(target)

      fetch(target.dataset.hypermediaGet)
        .then((response) => response.text())
        .then((html) => {
          target.outerHTML = html
          initMasonry()
          initHypermedia()
        })
    }
  }
})

export function initHypermedia() {
  for (const el of qsa("[data-hypermedia-get]")) {
    observer.observe(el)
  }

  for (const el of qsa("[data-hypermedia-post]")) {
    el.addEventListener("submit", (e) => {
      e.preventDefault()
      fetch(e.target.dataset.hypermediaPost, {
        method: "POST",
        body: new FormData(e.target),
      })
        .then((response) => response.text())
        .then((html) => {
          e.target.outerHTML = html
          e.target.scrollIntoView({ behavior: "smooth" })
          initMasonry()
          initHypermedia()
        })
    })
  }
}
